import store from '../../store/index.js'
export default {
	tabList: [{
			id: 1,
			name: 'Home'
		},
		{
			id: 2,
			name: 'About'
		},
		{
			id: 3,
			name: "Products"
		},
		{
			id: 4,
			name: 'Service'
		},
		{
			id: 5,
			name: 'News'
		},
		{
			id: 5,
			name: 'Career'
		},
		{
			id: 6,
			name: 'Contact'
		}
	],
	
	right:{
		qq:{
			text1:"Online consultation",
			text2:"Online communication, please click on me",
			button:"Online consultation"
		},
		phone:{
			text:"Contact Us"
		},
		opinion:{
			title:"Submit Feedback",
			text1:"Name",
			input1:'PPlease enter your namer',
			text2:"corporate name",
			input2:"Please enter the company name",
			text3:"Phone number",
			input3:"Please enter your phone number",
			text4:"Feedback Content",
			input4:"Please enter content",
			text5:"Submit Feedback",
			text6:"Email",
			input5:'Please enter your email address',
			text7:"WeChat mini program QR code:",
			text8:"QR code of official account:",
			text9:"Submitting feedback"
		}
	},

	homeAbout: {
		text:'ABOUT US',
		content1: "XXINTEK, 10+ years professional service provider of electronic components",
		content2: "We are an independent distributor of ADI, TI, ALTERA, XILINX and many other well-known semiconductor products.",
		content3: "Our business covers CPLD/FPGA, various high-end, low-end and field programmable logic technology, efficient DSP digital signal and ARM processor, FLASH storage and intelligent power management technology and other fields.",
		content4: "Since establishment, our business has maintained rapid development, covering several major industries such as automotive electronics, industrial control, power electronics, communication networks, aerospace, Internet of Things, and medical care.",
		content5: "Our vision is 'to become the most trusted IC component service provider for customers'; 'service, trust, win-win and innovation' are our core values. Our ultimate goal is to achieve a win-win situation for customers, employees, and shareholders, and strive to become one of the world's leading distributors of electronic components!",
		
		marketQuotations:{
			text1:'year',
			text2:'year',
			text3:'family',
			content1:"Core Airlines International was founded in 2014",
			content2:'More than 10 years of accumulated industry experience',
			content3:'Average age of employees: 26 years',
			content4:'More than 100 manufacturers'
		},
		right:{
			text1:'History',
			text2:'Honor',
			text3:'Company culture',
			text4:'Organization'
		},
		title:'Agency brand',
		title2:'Distribution',
		title3:'QUALITY',
		title4:'SERVICE',
		title5:'NEWS',
		//��������
		qualityAssurance: [{
				image: require('@/assets/img/qualityAssurance1.png'),
				title: 'Supplier Management',
				content: 'We are well aware of the concerns of domestic high-end customers about the quality of electronic components. The quality of products is related to the interests of customers, as well as the reputation and development of the company. We always take the control of supply channels and material quality as the foundation of the company.',
				time:'1.2s',
			},
			{
				image: require('@/assets/img/qualityAssurance2.jpg'),
				title: 'Incoming materials and receiving',
				content: 'The company has passed the ISO9001 quality certification system and adopted a strict quality management process to ensure that the products purchased and sold are brand new and original and originate from formal channels.',
				time:'1.4s',
			},
			{
				image: require('@/assets/img/qualityAssurance3.jpg'),
				title: 'Facilities and standards',
				content: 'Through many years of experience and selection, we have accumulated a number of excellent suppliers such as original factories, agents, OEM factories, established reputation traders, etc., to control the quality of the source of goods from the source and ensure that every piece of materials sold can be traced back to the source.',
				time:'1.6s',
			},
			{
				image: require('@/assets/img/qualityAssurance4.jpg'),
				title: 'Warehousing and packaging',
				content: 'Goods channel traceability: We have established detailed files for all suppliers, including their reputation, qualifications, records of previous purchases of materials, and customer feedback.',
				time:'1.8s',
			},
			{
				image: require('@/assets/img/qualityAssurance5.jpg'),
				title: 'Logistics and Delivery',
				content: 'Goods procurement and warehousing control: Every time we purchase a piece of goods, we require the supplier to specify the model, specification, quantity, batch, appearance, and grade of the purchased materials.',
				time:'2s',
			},
			{
				image: require('@/assets/img/qualityAssurance6.jpg'),
				title: 'Improvement and service',
				content: 'For devices with disputes between the supplier and the supplier, we can entrust international and nationally recognized third-party testing institutions to provide formal quality inspection reports.',
				time:'2.2s',
			}
		],
		qualityAssuranceButton:'Learn more',
		serviceItems: [{
				title: 'Overseas transactions',
				content: "Overseas original factory inventory, major agents' spot goods, support online and offline transactions, and provide settlement methods in RMB and USD.",
				image:require('@/assets/img/business2.png')
			},
			{
				title: 'Transit warehouse',
				content: 'Set up logistics transit warehouses in Hong Kong and Shenzhen to assist customers in solving logistics problems.',
				image:require('@/assets/img/storehouse.png')
			},
			{
				title: 'Military Technology',
				content: 'High-end military and civilian FPGA, CPLD, ADC, DAC, CONTROLLERS, DRIVERS, MEMORY, PMIC, and other categories provide you with global supply.',
				image:require('@/assets/img/militaryUse.png')
			},
			{
				title: 'Spot and futures resources',
				content: 'Gather global spot and futures resources from Europe, America, and Asia to search for scarce and unpopular products to stop production, and solve your research and development, production shortages, and small batch product problems.',
				image:require('@/assets/img/naturalResources.png')
			},
			{
				title: 'Complete set of services',
				content: 'According to customer and market needs, we have a regular stock of general materials in stock, and common brands such as TI, ADI, MAXIM, MINI, and ATMEL address customer BOM requirements in a one-stop manner.',
				image:require('@/assets/img/completeServices.png')
			},
			{
				title: 'Inventory consignment',
				content: 'Provide new original electronic inventory consignment sales. We have more than 1000 trading and end-user partners, coupled with our sensitive market sense and mature sales channels, to help you adjust and digest inventory pressure and activate funds.',
				image:require('@/assets/img/stock2.png')
			}
		],
		newsButton:'Explore more'
	},
	
	bottom:{
		class1:{
			title:'About us',
			text1:'About us',
			text2:'Development history',
			text3:'Qualifications honors',
			text4:'corporate culture',
		},
		class2:{
			title:'Product',
			text1:'Agent brand',
			text2:'Advantage distribution',
		},
		class3:{
			title:'Service Items',
			text1:'Quality Management System',
			text2:'Service trade',
			text3:'Product category',
		},
		class4:{
			title:'News',
		},
		class5:{
			title:'human resources',
			text1:"Recruitment position"
		},
		class6:{
			title:'contact us',
		},
		class7:{
			title:'Home',
			text1:'About us',
			text2:'Distribution',
			text3:'Quality',
			text4:'Service',
			text5:'News',
		}
	},
	
	subpageTop:{
		text1:'Your current location',
		text2:'Home'
	},
	
	aboutUs:{
		title:'About us',
		topList: [{
				id: 1,
				name: 'Company Profile'
			},
			{
				id: 2,
				name: 'Development history'
			},
			{
				id: 3,
				name: 'Qualifications honors'
			},
			{
				id: 4,
				name: 'Corporate culture'
			}
		],
		aboutUs:{
			title1:'Xxintek Co., Ltd',
			title2:'More than ten years of professional value-added service provider for electronic components'
		},
		marketQuotations:{
			text1:'year',
			text2:'year',
			text3:'family',
			content1:"Core Airlines International was founded in 2014",
			content2:'More than 10 years of accumulated industry experience',
			content3:'Average age of employees: 26 years',
			content4:'More than 100 manufacturers'
		},
		developmentHistory:{
			title:'Development history',
			list:[{
				image:require('@/assets/img/dh1.png'),
				time:'June 2014',
				content:'Establishment of the company'
			},
			{
				image:require('@/assets/img/dh2.png'),
				time:'August 22, 2019',
				content:'The company has passed the ISO quality management system certification'
			},
			{
				image:require('@/assets/img/dh3.png'),
				time:'January 20, 2022',
				content:'The company signed a sales representative certificate with Uptek Technology Co., Ltd. to serve as a sales representative in China and Hong Kong'
			},
			{
				image:require('@/assets/img/dh4.jpg'),
				time:'February 24, 2022',
				content:'The company signed the agency certificate with Jiangsu Quanli Semiconductor Co., Ltd'
			},
			{
				image:require('@/assets/img/dh5.png'),
				time:'March 4, 2022',
				content:'Signing of corporate ES proxy certificate'
			},
			{
				image:require('@/assets/img/dh6.png'),
				time:'March 5, 2022',
				content:'The company signed a proxy certificate with Beijing NEXTEL TECHNOLOGY Co., Ltd'
			},
			{
				image:require('@/assets/img/dh7.png'),
				time:'July 13, 2022',
				content:'The company signs the agency certificate with Everrate Technology Co., Ltd'
			}]
		},
		qualification:{
			title:'CERTIFICATES'
		},
		coreConcepts:{
			title:'Core concepts',
			content:'Service, trust, win-win situation, innovation',
			list:[{
				id:1,
				title:'Our Vision',
				content:'Become the most trusted IC component technology trade service provider in China'
			},
			{
				id:2,
				title:'Our Mission',
				content:'Achieve Customers, Achieve Brands, Achieve Employees'
			},
			{
				id:4,
				title:'Corporate Values',
				content:'Customer first, respect for individuals, integrity, teamwork, pursuit of excellence'
			}]
		}
	},
	
	productCenter:{
		text1:'Product Center',
		text2:"Product Center",
		title1:'Agency brand',
		title2:'Distribution',
		topList: [{
				id: 1,
				name: 'Agency brand'
			},
			{
				id: 2,
				name: 'Distribution'
			}
		],
	},
	
	serviceItems:{
		title:'Service Items',
		topList: [{
				id: 1,
				name: 'Service Items'
			},
			{
				id: 2,
				name: 'ISD Quality Management System'
			},
			{
				id: 3,
				name: 'service trade'
			},
			{
				id: 4,
				name: 'Product category'
			}
		],
		
		businessCharacteristics:{
			title:'Service Items',
			list:[{
				title:'Overseas transactions',
				content:'Overseas original factory inventory, major agents spot goods, support online and offline transactions, and provide settlement methods in RMB and USD.',
				image:require('@/assets/img/overseasTransactions.png')
			},
			{
				title:'Transit warehouse',
				content:'Set up logistics transit warehouses in Hong Kong and Shenzhen to assist customers in solving logistics problems.',
				image:require('@/assets/img/storehouse.jpg')
			},
			{
				title:'Military Technology',
				content:'High-end military and civilian FPGA, CPLD, ADC, DAC, CONTROLLERS, DRIVERS, MEMORY, PMIC, and other categories provide you with global supply.',
				image:require('@/assets/img/warIndustry.png')
			},
			{
				title:'Spot and futures resources',
				content:'Gather global spot and futures resources from Europe, America, and Asia to search for scarce and unpopular products to stop production, and solve your research and development, production shortages, and small batch product problems.',
				image:require('@/assets/img/spotGoods.png')
			},
			{
				title:'BOM Requirements',
				content:'According to customer and market needs, we have a regular stock of general materials in stock, and common brands such as TI, ADI, MAXIM, MINI, and ATMEL address customer BOM requirements in a one-stop manner.',
				image:require('@/assets/img/BOM.png')
			},
			{
				title:'Inventory consignment',
				content:'Provide new original electronic inventory consignment sales. We have more than 1000 trading and end-user partners, coupled with our sensitive market sense and mature sales channels, to help you adjust and digest inventory pressure and activate funds.',
				image:require('@/assets/img/stock.jpg')
			}]
		},
		
		qualityManagement:{
			title:'Core Airlines International ISD Quality Management System',
			content1:'We are well aware of the concerns of domestic high-end customers about the quality of electronic components. The quality of products is related to the interests of customers, as well as the reputation and development of the company. We always take the control of supply channels and material quality as the foundation of the company. The company has passed the ISO9001 quality certification system and adopted a strict quality management process to ensure that the products purchased and sold are brand new and original and originate from formal channels. Goods channel traceability: We have established detailed files for all suppliers, including their reputation, qualifications, records of previous purchases of materials, and customer feedback. Through many years of experience and selection, we have accumulated a number of excellent suppliers such as original factories, agents, OEM factories, established reputation traders, etc., to control the quality of the source of goods from the source and ensure that every piece of materials sold can be traced back to the source.',
			content2:'Goods procurement and warehousing control: Every time we purchase a piece of goods, we require the supplier to specify the model, specification, quantity, batch, appearance, and grade of the purchased materials. After the arrival of materials, the quality inspection department of the company shall inspect and confirm them according to the material standards in the purchase contract, and submit them to the warehouse personnel for warehousing if they meet the requirements. The warehouse personnel shall affix a qualified label and store the materials according to the packaging requirements of different materials. For devices with disputes between the supplier and the supplier, we can entrust international and nationally recognized third-party testing institutions to provide formal quality inspection reports.'
		},
		
		serviceTrade:{
			title:'Service trade',
			list:[{
				title:'New energy',
				content:'Wind energy, photovoltaic solar energy, LED drives, ceramic materials, thermal conductive materials, supercapacitors, etc.',
				image:require('@/assets/img/newEnergy.png')
			},
			{
				title:'Automotive Electronics',
				content:'Video entertainment, panoramic reversing system, TUNER, GPS, Internet of Vehicles, etc.',
				image:require('@/assets/img/AutomotiveElectronics.png')
			},
			{
				title:'Electric power meter',
				content:'Intelligent electricity meter, power carrier meter reading system, metering chip, optocoupler, etc.',
				image:require('@/assets/img/ElectricPowerMeter.png')
			},
			{
				title:'Security monitoring',
				content:'DVR, IP-CAMERA, intelligent monitoring.',
				image:require('@/assets/img/SecurityMonitoring.png')
			},
			{
				title:'Military Technology',
				content:'Aviation, aerospace, weapons, satellite navigation, oil exploration, etc.',
				image:require('@/assets/img/warIndustry.png')
			},
			{
				title:'RF Microwave',
				content:'Wireless communication (repeater, base station, RRU, WLAN), WIFI, CMMB, ISM (industrial, scientific, medical), etc.',
				image:require('@/assets/img/RFMicrowave.png')
			},
			{
				title:'Internet of Things',
				content:'RFID, private network communication, communication module (M2M), intelligent transportation, smart home.',
				image:require('@/assets/img/InternetThings.png')
			},
			{
				title:'industrial automation',
				content:'RFID, private network communication, communication module (M2M), intelligent transportation, smart home.',
				image:require('@/assets/img/industrialAutomation.png')
			}]
		},
		
		productCategory:{
			title:'Product category',
			list:[{
				class:'Component composition',
				data:[{
					name:'IC (Integrated Circuits)'
				},
				{
					name:'resistors'
				},
				{
					name:'capacitors'
				},
				{
					name:'diodes'
				},
				{
					name:'transistors'
				},
				{
					name:'switches'
				},
				{
					name:'connectors'
				},
				{
					name:'sockets'
				}]
			}]
		}
	},
	
	news:{
		title:'News',
		title2:'News detail',
		text1:'Release time',
		topList: [{
				id: 1,
				name: 'News'
			}
		],
		topList2: [{
				id: 1,
				name: 'News detail'
			}
		],
	},
	
	humanResources:{
		title:'Human resources',
		topList: [{
				id: 1,
				name: "Recruitment position's"
			}
		],
		title2:"Recruitment position's",
		tableText1:'Job Responsibilities',
		tableText2:'job content',
		tableText3:'working hours',
		tableText4:'Compensation and benefits',
		tableText5:'Position Name:',
		tableText6:'record of formal schooling:',
		tableText7:'pay:',
		tableText8:'Release time:',
	},
	
	contactUs:{
		title:'Contact us',
		title2:'Contact information',
		text1:'Phone',
		text2:'Address',
		text3:"Email",
		text4:"Fax",
		text5:"Address Shenzhen Modern Window",
		topList: [{
				id: 1,
				name: 'Contact information'
			}
		],
	}
}
