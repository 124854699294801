import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../components/Home.vue'
// import Search from '../components/Search.vue'

// 懒加载 动态加载
const Home = () => import('../business/home/index.vue')
const AboutUs = () => import('../business/aboutUs/index.vue')
const ProductCenter = () => import('../business/productCenter/index.vue')
const ServiceItems = () => import('../business/serviceItems/index.vue')
const News = () => import('../business/news/index.vue')
const Details = () => import('../business/news/details.vue')
const HumanResources = () => import('../business/humanResources/index.vue')
const ContactUs = () => import('../business/contactUs/index.vue')

Vue.use(VueRouter)

// 路由操作 no-referrer 解决跨域问题
const routes = [
  {
    path: '/', component: Home, meta: {
      title: "深圳市芯航国际电子有限公司 官网-成为中国最值得信赖的IC元器件科技贸易服务商 芯片代理_ic代理 _芯片配单_电子元器件_半导体_军工",
      name: "referrer",
      content: "no-referrer"
    }
  },
  { path: '/', redirect: '/aboutUs' },
  {
    path: '/aboutUs', component: AboutUs, meta: {
      title: "关于我们",
      name: "referrer",
      content: "no-referrer"
    }
  },
  { path: '/', redirect: '/productCenter' },
  {
    path: '/productCenter', component: ProductCenter, meta: {
      title: "产品中心",
      name: "referrer",
      content: "no-referrer"
    }
  },
  { path: '/', redirect: '/serviceItems' },
  {
    path: '/serviceItems', component: ServiceItems, meta: {
      title: "服务项目",
      name: "referrer",
      content: "no-referrer"
    }
  },
  { path: '/', redirect: '/news' },
  {
    path: '/news', component: News, meta: {
      title: "新闻中心",
      name: "referrer",
      content: "no-referrer"
    }
  },
  { path: '/', redirect: '/news' },
  {
    path: '/details', component: Details, meta: {
      title: "新闻详情",
      name: "referrer",
      content: "no-referrer"
    }
  },
  { path: '/', redirect: '/humanResources' },
  {
    path: '/humanResources', component: HumanResources, meta: {
      title: "人力资源",
      name: "referrer",
      content: "no-referrer"
    }
  },
  { path: '/', redirect: '/contactUs' },
  {
    path: '/contactUs', component: ContactUs, meta: {
      title: "联系方式",
      name: "referrer",
      content: "no-referrer"
    }
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.NODE_ENV === 'production' ? '' : '/',
  routes
})

export default router
