import store from '../../store/index.js'
export default {
	tabList: [{
			id: 1,
			name: '首页'
		},
		{
			id: 2,
			name: '关于我们'
		},
		{
			id: 3,
			name: '产品中心'
		},
		{
			id: 4,
			name: '服务项目'
		},
		{
			id: 5,
			name: '新闻中心'
		},
		{
			id: 5,
			name: '职业发展'
		},
		{
			id: 6,
			name: '联系我们'
		}
	],
	
	right:{
		qq:{
			text1:"在线咨询",
			text2:"在线沟通，请点击我",
			button:"在线咨询"
		},
		phone:{
			text:"联系我们"
		},
		opinion:{
			title:"提交反馈",
			text1:"姓名",
			input1:'请输入姓名',
			text2:"公司名称",
			input2:"请输入公司名称",
			text3:"手机号码",
			input3:"请输入手机号码",
			text4:"反馈内容",
			input4:"请输入内容",
			text5:"提交反馈",
			text6:"邮箱",
			input5:'请输入邮箱',
			text7:"微信小程序二维码：",
			text8:"公众号二维码：",
			text9:"提交反馈中"
		}
	},
	
	homeAbout:{
		text:'关于芯航国际',
		content1:'深圳市芯航国际电子有限公司专业代理分销ADI、TI、ALTERA、XILINX等国际知名半导体产品，业务涉及CPLD/FPGA各种中高低端及现场可编程逻辑技术、高效的DSP数字信号及ARM处理器、FLASH存储及智能电源管理技术等领域。公司自成立以来，业务保持高速发展，业务主要覆盖汽车电子、工业控制、电力电子、通讯网络、航空航天、物联网、医疗几大行业。',
		content2:'公司自成立以来始终以“缔造顾客信赖的一流企业!营造员工发展的自由空间!创造股东增值的公正平台!”为公司的发展理念；以“成为中国最值得信赖的IC元器件科技贸易服务商”为愿景；以“服务、信任、共赢、创新”为价值观。最终要让客户、员工、股东实现共赢，同时将公司打造为国内领先的元器件企业!',
		marketQuotations:{
			text1:'年',
			text2:'岁',
			text3:'家',
			content1:"芯航国际创办于2014年",
			content2:'10年以上行业经验累计',
			content3:'员工平均年龄26岁',
			content4:'厂家超过100余家'
		},
		right:{
			text1:'发展历程',
			text2:'资质荣誉',
			text3:'企业文化',
			text4:'组织架构'
		},
		title:'代理品牌',
		title2:'优势分销',
		title3:'质量保障',
		title4:'服务项目',
		title5:'新闻中心',
		//质量保障
		qualityAssurance: [{
				image: require('@/assets/img/qualityAssurance1.png'),
				title: '供应商管理',
				content: '我们深知国内高端客户对电子元器件品质的担忧，产品的质量关系到客户的利益,也关系到公司的信誉和发展，我们始终把供应渠道和物料质量的把控作为公司的立身之本。',
				time:'1.2s',
			},
			{
				image: require('@/assets/img/qualityAssurance2.jpg'),
				title: '来料与接收',
				content: '公司通过了ISO9001质量认证体系,采用严格的质量管理流程确保采购与销售的产品为全新原装及源自正规渠道。',
				time:'1.4s',
			},
			{
				image: require('@/assets/img/qualityAssurance3.jpg'),
				title: '设施与标准',
				content: '多年的从业经验、筛选积累了一批原厂、代理、OEM工厂、老牌信誉贸易商等优秀供应商，从源头上把控货源的质量,确保每一件销售的物料都可以进行源头追溯。',
				time:'1.6s',
			},
			{
				image: require('@/assets/img/qualityAssurance4.jpg'),
				title: '仓储与包装',
				content: '货物渠道追溯:我们对所有供方都建立了信誉、资质、历次采购物料记录及客户反馈等详细档案。',
				time:'1.8s',
			},
			{
				image: require('@/assets/img/qualityAssurance5.jpg'),
				title: '物流与交付',
				content: '货物采购入库控制:我们每采购一件货品,均对供方提出采购物料的型号规格、数量、批次、外观、等级等要求。',
				time:'2s',
			},
			{
				image: require('@/assets/img/qualityAssurance6.jpg'),
				title: '改进与服务',
				content: '对于供需双方有疑问争议的器件,我们可以委托国际及国家认可的第三方检测机构提供正规质检报告。',
				time:'2.2s',
			}
		],
		qualityAssuranceButton:'了解更多',
		serviceItems: [{
				title: '海外交易',
				content: '海外原厂库存，各大代理现货，支持线上、线下交易提供人名币、美金的结款结算方式。',
				image:require('@/assets/img/business2.png')
			},
			{
				title: '中转仓库',
				content: '在香港，深圳设立物流中转仓库，协助客户解决物流难题。',
				image:require('@/assets/img/storehouse.png')
			},
			{
				title: '军用科技',
				content: '高端军民 FPGA，CPLD，ADC，DAC，CONTROLLERS，DRIVERS，MEMORY，PMIC等种类，为你提供全球货源。',
				image:require('@/assets/img/militaryUse.png')
			},
			{
				title: '现货、期货资源',
				content: '集合欧美亚全球现货、期货资源，为您搜寻紧缺偏冷门停产器件，解决您的研发、生产短缺、小批量物件难题。',
				image:require('@/assets/img/naturalResources.png')
			},
			{
				title: '齐套服务',
				content: '根据客户和市场需求我们常备通用物料现货库存，TI、ADI、MAXIM、MINI、ATMEL等常用品牌一站式解决客户的BOM表需求。',
				image:require('@/assets/img/completeServices.png')
			},
			{
				title: '库存代销',
				content: '提供全新原装电子库存代销，我们有1000多家贸易和最终用户合作伙伴，加之我们灵敏的市场触觉和成熟的销售渠道，帮您调剂消化库存压力盘活资金。',
				image:require('@/assets/img/stock2.png')
			}
		],
		newsButton:'探索更多'
	},
	
	bottom:{
		class1:{
			title:'关于我们',
			text1:'公司简介',
			text2:'发展历程',
			text3:'资质荣誉',
			text4:'企业文化',
		},
		class2:{
			title:'产品中心',
			text1:'代理品牌',
			text2:'优势分销',
		},
		class3:{
			title:'服务项目',
			text1:'芯航国际品质管理体系',
			text2:'服务行业',
			text3:'产品类别',
		},
		class4:{
			title:'新闻资讯',
		},
		class5:{
			title:'人力资源',
			text1:"招聘岗位"
		},
		class6:{
			title:'联系我们',
		},
		class7:{
			title:'首页',
			text1:'关于我们',
			text2:'优势分销',
			text3:'质量保障',
			text4:'服务项目',
			text5:'新闻中心',
		}
	},
	
	subpageTop:{
		text1:'您现在的位置',
		text2:'首页'
	},
	
	aboutUs:{
		title:'关于我们',
		topList: [{
				id: 1,
				name: '公司简介'
			},
			{
				id: 2,
				name: '发展历程'
			},
			{
				id: 3,
				name: '资质荣誉'
			},
			{
				id: 4,
				name: '企业文化'
			}
		],
		aboutUs:{
			title1:'芯航国际电子有限公司',
			title2:'十余年专业电子元器件增值服务商'
		},
		marketQuotations:{
			text1:'年',
			text2:'岁',
			text3:'家',
			content1:"芯航国际创办于2014年",
			content2:'10年以上行业经验累计',
			content3:'员工平均年龄26岁',
			content4:'厂家超过100余家'
		},
		developmentHistory:{
			title:'发展历程',
			list:[{
				image:require('@/assets/img/dh1.png'),
				time:'2014年6月',
				content:'公司成立'
			},
			{
				image:require('@/assets/img/dh2.png'),
				time:'2019年8月22日',
				content:'公司通过ISO质量管理体系认证'
			},
			{
				image:require('@/assets/img/dh3.png'),
				time:'2022年1月20日',
				content:'公司与Uptek科技有限公司签署将在中国和香港担任销售代表证书'
			},
			{
				image:require('@/assets/img/dh4.jpg'),
				time:'2022年2月24日',
				content:'公司与江苏铨力半导体有限公司签署代理证书'
			},
			{
				image:require('@/assets/img/dh5.png'),
				time:'2022年3月4日',
				content:'公司ES代理证书签署'
			},
			{
				image:require('@/assets/img/dh6.png'),
				time:'2022年3月5日',
				content:'公司与北京耐科特科技有限公司签署代理证书'
			},
			{
				image:require('@/assets/img/dh7.png'),
				time:'2022年7月13日',
				content:'公司与恒率科技有限公司签署代理证书'
			}]
		},
		qualification:{
			title:'资质荣誉'
		},
		coreConcepts:{
			title:'核心理念',
			content:'服务，信任，共赢，创新',
			list:[{
				id:1,
				title:'我们的愿景',
				content:'成为中国最值得信赖的IC元器件科技贸易服务商'
			},
			{
				id:2,
				title:'我们的使命',
				content:'成就客户，成就品牌，成就员工'
			},
			{
				id:4,
				title:'企业价值观',
				content:'客户第一，尊重个人，诚信正直，团队协作、追求卓越'
			}]
		}
	},
	
	productCenter:{
		text1:'产品中心',
		text2:"产品中心",
		title1:'代理品牌',
		title2:'优势分销',
		topList: [{
				id: 1,
				name: '代理品牌'
			},
			{
				id: 2,
				name: '优势分销'
			}
		],
	},
	
	serviceItems:{
		title:'服务项目',
		topList: [{
				id: 1,
				name: '服务项目'
			},
			{
				id: 2,
				name: '芯航国际ISD品质管理体系'
			},
			{
				id: 3,
				name: '服务行业'
			},
			{
				id: 4,
				name: '产品类别'
			}
		],
		businessCharacteristics:{
			title:'服务项目',
			list:[{
				title:'海外交易',
				content:'海外原厂库存，各大代理现货，支持线上、线下交易提供人名币、美金的结款结算方式。',
				image:require('@/assets/img/overseasTransactions.png')
			},
			{
				title:'中转仓库',
				content:'在香港，深圳设立物流中转仓库，协助客户解决物流难题。',
				image:require('@/assets/img/storehouse.jpg')
			},
			{
				title:'军用科技',
				content:'高端军民 FPGA，CPLD，ADC，DAC，CONTROLLERS，DRIVERS，MEMORY，PMIC等种类，为你提供全球货源。',
				image:require('@/assets/img/warIndustry.png')
			},
			{
				title:'现货、期货资源',
				content:'集合欧美亚全球现货、期货资源，为您搜寻紧缺偏冷门停产器件，解决您的研发、生产短缺、小批量物件难题。',
				image:require('@/assets/img/spotGoods.png')
			},
			{
				title:'BOM需求',
				content:'根据客户和市场需求我们常备通用物料现货库存，TI、ADI、MAXIM、MINI、ATMEL等常用品牌一站式解决客户的BOM表需求。',
				image:require('@/assets/img/BOM.png')
			},
			{
				title:'库存代销',
				content:'提供全新原装电子库存代销，我们有1000多家贸易和最终用户合作伙伴，加之我们灵敏的市场触觉和成熟的销售渠道，帮您调剂消化库存压力盘活资金。',
				image:require('@/assets/img/stock.jpg')
			}]
		},
		
		qualityManagement:{
			title:'芯航国际ISD品质管理体系',
			content1:'我们深知国内高端客户对电子元器件品质的担忧，产品的质量关系到客户的利益,也关系到公司的信誉和发展，我们始终把供应渠道和物料质量的把控作为公司的立身之本。公司通过了ISO9001质量认证体系,采用严格的质量管理流程确保采购与销售的产品为全新原装及源自正规渠道。货物渠道追溯:我们对所有供方都建立了信誉、资质、历次采购物料记录及客户反馈等详细档案。多年的从业经验、筛选积累了一批原厂、代理、OEM工厂、老牌信誉贸易商等优秀供应商，从源头上把控货源的质量,确保每一件销售的物料都可以进行源头追溯。',
			content2:'货物采购入库控制:我们每采购一件货品,均对供方提出采购物料的型号规格、数量、批次、外观、等级等要求。物料到货后公司质检部门按采购合同的物料标准进行检查确认,符合要求方交仓库人员入库。仓库人员打上合格标签和按不同物料的包装要求入库存放。对于供需双方有疑问争议的器件,我们可以委托国际及国家认可的第三方检测机构提供正规质检报告。'
		},
		
		serviceTrade:{
			title:'服务行业',
			list:[{
				title:'新能源',
				content:'风能、光伏太阳能、LED 驱动、陶瓷材料、导热材料、超级电容等。',
				image:require('@/assets/img/newEnergy.png')
			},
			{
				title:'汽车电子',
				content:'影音娱乐、全景倒车系统、TUNER、GPS、车联网等。',
				image:require('@/assets/img/AutomotiveElectronics.png')
			},
			{
				title:'电力电表',
				content:'智能电表、电力载波抄表系统、计量芯片，光耦等。',
				image:require('@/assets/img/ElectricPowerMeter.png')
			},
			{
				title:'安防监控',
				content:'DVR、IP-CAMERA、智能监控。',
				image:require('@/assets/img/SecurityMonitoring.png')
			},
			{
				title:'军用科技',
				content:'航空、航天、兵器、卫星导航、石油勘探等。',
				image:require('@/assets/img/warIndustry.png')
			},
			{
				title:'射频微波',
				content:'无线通信（直放站、基站、RRU、WLAN）、WIFI、CMMB、ISM（工业、科学、医疗）等。',
				image:require('@/assets/img/RFMicrowave.png')
			},
			{
				title:'物联网',
				content:'RFID、专网通信、通信模块(M2M)、智能交通、智能家居。',
				image:require('@/assets/img/InternetThings.png')
			},
			{
				title:'工业自动化',
				content:'RFID、专网通信、通信模块(M2M)、智能交通、智能家居。',
				image:require('@/assets/img/industrialAutomation.png')
			}]
		},
		
		productCategory:{
			title:'产品类别',
			list:[{
				class:'元器件组成',
				data:[{
					name:'IC (Integrated Circuits)集成电路'
				},
				{
					name:'resistors电阻'
				},
				{
					name:'capacitors电容'
				},
				{
					name:'diodes二极管'
				},
				{
					name:'transistors三极管'
				},
				{
					name:'switches开关'
				},
				{
					name:'connectors连接器'
				},
				{
					name:'sockets插座'
				}]
			}]
		}
	},
	
	news:{
		title:'新闻中心',
		title2:'新闻详情',
		text1:'发布时间',
		topList: [{
				id: 1,
				name: '新闻中心'
			}
		],
		topList2: [{
				id: 1,
				name: '新闻详情'
			}
		],
	},
	
	humanResources:{
		title:'人力资源',
		topList: [{
				id: 1,
				name: '招聘岗位'
			}
		],
		title2:'招聘职位',
		tableText1:'岗位职责',
		tableText2:'工作内容',
		tableText3:'工作时间',
		tableText4:'薪酬福利',
		tableText5:'职位名称:',
		tableText6:'学历:',
		tableText7:'薪酬:',
		tableText8:'发布时间:',
	},
	
	contactUs:{
		title:'联系我们',
		title2:'联系方式',
		text1:'总机',
		text2:'地址',
		text3:"邮件",
		text4:"传真",
		text5:"深圳现代之窗地址",
		topList: [{
				id: 1,
				name: '联系方式'
			}
		],
	}
}