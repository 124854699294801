import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	windowWidth:0,//页面宽度
	windowHeight:0,//页面高度
	scroll:0,//移动高度
	tabsIndex:null,//tabs下标位置
	routerPath:null,//获取当前路由地址
	title:'Xintek is an electronic component distributor founded by a group of young people with many years of experience in the electronic industry and dreams',
	propaganda:null,
	prType:null,//产品类型
	name:null,//产品厂家名/产品类型名
	inputSearch:null,//产品搜索内容
	contactType:false,//右边菜单栏联系弹框状态
	topInput:null,//顶部搜索框的值
	language:JSON.parse(sessionStorage.getItem("LANGUAGE")) || (navigator.language== 'zh-CN' ? 'cn' : 'en'),//语言
	anchorPointIndex:0,//服务项目下标
  },
  mutations: {
	WINDOW_WIDTH(state,{ windowWidth, windowHeight }){
		state.windowWidth=windowWidth
	},
	WINDOW_HEIGHT(state,{ windowWidth, windowHeight }){
		state.windowHeight=windowHeight
	},
	SCROLL(state,{ scroll }){
		state.scroll=scroll
	},
	TABS_INDEX(state,{ tabsIndex }){
		state.tabsIndex=tabsIndex
	},
	ROUTER_PATH(state,{ routerPath }){
		state.routerPath=routerPath
	},
	PROPAGANDA(state,{ propaganda }){
		state.propaganda=propaganda
	},
	PRTYPE(state,{ prType }){
		state.prType=prType
	},
	NAME(state,{ name }){
		state.name=name
	},
	INPUT_SEARCH(state,{ inputSearch }){
		state.inputSearch = inputSearch
	},
	CONTACT_TYPE(state,{ contactType }){
		state.contactType = contactType
	},
	TOP_INPUT(state,{ topInput }){
		state.topInput = topInput
	},
	LANGUAGE(state,{ language }){
		state.language = language
		sessionStorage.setItem("LANGUAGE", JSON.stringify(language))
	},
	ANCHOR_POINT_INDEX(state,{ anchorPointIndex }){
		state.anchorPointIndex = anchorPointIndex
	},
  },
  actions: {
	setWindowWidthHeight({ commit },data){
		commit('WINDOW_WIDTH',{windowWidth:data.windowWidth})
		commit('WINDOW_HEIGHT',{windowHeight:data.windowHeight})
	},
	setScroll({ commit },data){
		commit('SCROLL',{scroll:data.scroll})
	},
	setTabsIndex({ commit },data){
		commit('TABS_INDEX',{tabsIndex:data.tabsIndex})
	},
	setRouterPath({ commit },data){
		commit('ROUTER_PATH',{routerPath:data.routerPath})
	},
	setPropaganda({ commit },data){
		commit('PROPAGANDA',{propaganda:data.propaganda})
	},
	setPrType({ commit },data){
		console.log('获取vuex数据',data)
		commit('PRTYPE',{prType:data.prType})
	},
	setName({ commit },data){
		commit('NAME',{name:data.name})
	},
	setInputSearch({ commit },data){
		// console.log('获取vuex数据',data)
		commit('INPUT_SEARCH',{inputSearch:data.inputSearch})
	},
	setContactType({ commit },data){
		// console.log('获取vuex数据',data)
		commit('CONTACT_TYPE',{contactType:data.contactType})
	},
	settopInput({ commit },data){
		// console.log('获取vuex数据',data)
		commit('TOP_INPUT',{topInput:data.topInput})
	},
	setLanguage({ commit },data){
		commit('LANGUAGE',{language:data.language})
	},
	setAnchorPointIndex({ commit },data){
		commit('ANCHOR_POINT_INDEX',{anchorPointIndex:data.anchorPointIndex})
	}
  },
  modules: {
	
  }
})
