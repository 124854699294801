<template>
	<div id="app">
		<!-- 加了keep-alive 就不会销毁 会被缓存下来 -->
		<!-- <keep-alive> -->
		<!-- 路由占位符 -->
		<router-view></router-view>
		<!-- </keep-alive> -->
	</div>
</template>

<script>
	export default {
		name: "app",
		data() {
			return {
				
			};
		},
		watch: {
			//监听浏览器窗口改变
			// windowWidth(val) {
			// 	this.windowWidth = val;
			// 	//监听高宽变化后后把监听的高宽赋值给vuex
			// 	this.$store.dispatch("setWindowWidthHeight", {
			// 	    windowWidth: val,
			// 	});
			// 	this.widthHide()
			// },
			// windowHeight(val) {
			// 	//监听高宽变化后后把监听的高宽赋值给vuex
			// 	this.$store.dispatch("setWindowWidthHeight", {
			// 	    windowHeight: val,
			// 	});
			// 	// this.windowHeight = val;
			// },
		},
		created() {
			
		},
		computed: {
			
		},
		mounted() {
			var _hmt = _hmt || [];
			(function() {
			  var hm = document.createElement("script");
			  hm.src = "https://hm.baidu.com/hm.js?b468ef4f4636a2cdf554240b41976132";
			  var s = document.getElementsByTagName("script")[0]; 
			  s.parentNode.insertBefore(hm, s);
			})();
		},
		//销毁,否则跳到别的路由还是会出现
		destroyed(){
		    
		},
		methods: {
			
		},
	};
</script>

<style lang="less">
	
</style>
